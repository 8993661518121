import React from 'react'

import FullWidthWrapper from '../components/FullWidthWrapper'
import ForegroundLayout from '../components/ForegroundLayout'
import BigHeading from '../components/BigHeading'

const Privacy = () => (
  <FullWidthWrapper
    css={`
      background-color: #ffffff;
    `}
  >
    <ForegroundLayout
      css={`
        padding: 25vh 0;
      `}
    >
      <div>
        <BigHeading
          dark
          css={`
            margin-top: 0;
          `}
        >
          PRIVACY POLICY
        </BigHeading>
        <h6>LAST UPDATED JANUARY 30, 2019</h6>
        <p>
          Thank you for choosing to be part of our community at Orange
          Astronaut, doing business as Orange Astronaut (“Orange Astronaut”,
          “WE”, “US”, or “OUR”). We are committed to protecting your personal
          information and your right to privacy. If you have any questions or
          concerns about our policy, or our practices with regards to your
          personal information, please contact us at info@orangeastronaut.com.
          When you visit our website https://orangeastronaut.com, and use our
          services, you trust us with your personal information. We take your
          privacy very seriously. In this privacy notice, we describe our
          privacy policy. We seek to explain to you in the clearest way possible
          what information we collect, how we use it and what rights you have in
          relation to it. We hope you take some time to read through it
          carefully, as it is important. If there are any terms in this privacy
          policy that you do not agree with, please discontinue use of our Sites
          and our services.
        </p>
        <p>
          This privacy policy applies to all information collected through our
          website (such as https://orangeastronaut.com), and/or any related
          services, sales, marketing or events (we refer to them collectively in
          this privacy policy as the “SITES”).
        </p>
        <p>
          PLEASE READ THIS PRIVACY POLICY CAREFULLY AS IT WILL HELP YOU MAKE
          INFORMED DECISIONS ABOUT SHARING YOUR PERSONAL INFORMATION WITH US.
        </p>
        <h4>TABLE OF CONTENTS</h4>
        <ol>
          <li>WHAT INFORMATION DO WE COLLECT?</li>
          <li>HOW DO WE USE YOUR INFORMATION?</li>
          <li>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</li>
          <li>WHO WILL YOUR INFORMATION BE SHARED WITH?</li>
          <li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
          <li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
          <li>DO WE COLLECT INFORMATION FROM MINORS?</li>
          <li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li>DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</li>
          <li>DO WE MAKE UPDATES TO THIS POLICY?</li>
          <li>HOW CAN YOU CONTACT US ABOUT THIS POLICY?</li>
        </ol>
        <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
        <h5>PERSONAL INFORMATION YOU DISCLOSE TO US</h5>
        <p>
          <strong>
            IN SHORT: We collect personal information that you provide to us
            such as name, address, contact information, passwords and security
            data, and payment information.
          </strong>
        </p>
        <p>
          We collect personal information that you voluntarily provide to us
          when expressing an interest in obtaining information about us or our
          products and services, when participating in activities on the Sites
          or otherwise contacting us.
        </p>
        <p>
          The personal information that we collect depends on the context of
          your interactions with us and the Sites, the choices you make and the
          products and features you use. The personal information we collect can
          include the following:
        </p>
        <p>
          NAME AND CONTACT DATA.&nbsp;We collect your first and last name, email
          address, postal address, phone number, and other similar contact data.
        </p>
        <p>
          CREDENTIALS.&nbsp;We collect passwords, password hints, and similar
          security information used for authentication and account access.
        </p>
        <p>
          PAYMENT DATA.&nbsp;We collect data necessary to process your payment
          if you make purchases, such as your payment instrument number (such as
          a credit card number), and the security code associated with your
          payment instrument. All payment data is stored by our payment
          processor and you should review its privacy policies and contact the
          payment processor directly to respond to your questions.
        </p>
        <p>
          All personal information that you provide to us must be true, complete
          and accurate, and you must notify us of any changes to such personal
          information.
        </p>
        <h5>INFORMATION AUTOMATICALLY COLLECTED</h5>
        <p>
          <strong>
            IN SHORT: Some information – such as IP address and/or browser and
            device characteristics – is collected automatically when you visit
            our Sites.
          </strong>
        </p>
        <p>
          We automatically collect certain information when you visit, use or
          navigate the Sites. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Sites and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Sites, and for our internal analytics and reporting purposes.
        </p>
        <p>
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <h5>INFORMATION COLLECTED FROM OTHER SOURCES</h5>
        <p>
          <strong>
            IN SHORT: We may collect limited data from public databases,
            marketing partners, and other outside sources.&nbsp;
          </strong>
        </p>
        <p>
          We may obtain information about you from other sources, such as public
          databases, joint marketing partners, as well as from other third
          parties. Examples of the information we receive from other sources
          include: social media profile information; marketing leads and search
          results and links, including paid listings (such as sponsored links).
        </p>
        <h4>2. HOW DO WE USE YOUR INFORMATION?</h4>
        <p>
          <strong>
            IN SHORT: We process your information for purposes based on
            legitimate business interests, the fulfillment of our contract with
            you, compliance with our legal obligations, and/or your consent.
          </strong>
        </p>
        <p>
          We use personal information collected via our Sites for a variety of
          business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business
          interests, in order to enter into or perform a contract with you, with
          your consent, and/or for compliance with our legal obligations. We
          indicate the specific processing grounds we rely on next to each
          purpose listed below.
        </p>
        <p>We use the information we collect or receive:</p>
        <p>
          * TO SEND YOU MARKETING AND PROMOTIONAL COMMUNICATIONS.&nbsp;We and/or
          our third party marketing partners may use the personal information
          you send to us for our marketing purposes, if this is in accordance
          with your marketing preferences. You can opt-out of our marketing
          emails at any time (see the ”&nbsp;WHAT ARE YOUR PRIVACY RIGHTS
          (#privacyrights) &nbsp;” below).
        </p>
        <p>
          * REQUEST FEEDBACK.&nbsp;We may use your information to request
          feedback and to contact you about your use of our Sites.
        </p>
        <p>
          * TO PROTECT OUR SITES.&nbsp;We may use your information as part of
          our efforts to keep our Sites safe and secure (for example, for fraud
          monitoring and prevention).
        </p>
        <p>* TO ENFORCE OUR TERMS, CONDITIONS AND POLICIES.</p>
        <p>
          * TO RESPOND TO LEGAL REQUESTS AND PREVENT HARM.&nbsp;If we receive a
          subpoena or other legal request, we may need to inspect the data we
          hold to determine how to respond.
        </p>
        <p>
          * FOR OTHER BUSINESS PURPOSES.&nbsp;We may use your information for
          other Business Purposes, such as data analysis, identifying usage
          trends, determining the effectiveness of our promotional campaigns and
          to evaluate and improve our Sites, products, services, marketing and
          your experience.
        </p>
        <h4>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
        <p>
          <strong>
            IN SHORT: We only share information with your consent, to comply
            with laws, to protect your rights, or to fulfill business
            obligations.&nbsp;
          </strong>
        </p>
        <p>We may process or share data based on the following legal basis:</p>
        <p>
          * CONSENT: We may process your data if you have given us specific
          consent to use your personal information in a specific purpose.
        </p>
        <p>
          * LEGITIMATE INTERESTS: We may process your data when it is reasonably
          necessary to achieve our legitimate business interests.
        </p>
        <p>
          * PERFORMANCE OF A CONTRACT:&nbsp;Where we have entered into a
          contract with you, we may process your personal information to fulfill
          the terms of our contract.
        </p>
        <p>
          * LEGAL OBLIGATIONS: We may disclose your information where we are
          legally required to do so in order to comply with applicable law,
          governmental requests, a judicial proceeding, court order, or legal
          process, such as in response to a court order or a subpoena (including
          in response to public authorities to meet national security or law
          enforcement requirements).
        </p>
        <p>
          * VITAL INTERESTS: We may disclose your information where we believe
          it is necessary to investigate, prevent, or take action regarding
          potential violations of our policies, suspected fraud, situations
          involving potential threats to the safety of any person and illegal
          activities, or as evidence in litigation in which we are involved.
        </p>
        <p>
          More specifically, we may need to process your data or share your
          personal information in the following situations:
        </p>
        <p>
          * VENDORS, CONSULTANTS AND OTHER THIRD-PARTY SERVICE
          PROVIDERS.&nbsp;We may share your data with third party vendors,
          service providers, contractors or agents who perform services for us
          or on our behalf and require access to such information to do that
          work. Examples include: payment processing, data analysis, email
          delivery, hosting services, customer service and marketing efforts. We
          may allow selected third parties to use tracking technology on the
          Sites, which will enable them to collect data about how you interact
          with the Sites over time. This information may be used to, among other
          things, analyze and track data, determine the popularity of certain
          content and better understand online activity. Unless described in
          this Policy, we do not share, sell, rent or trade any of your
          information with third parties for their promotional purposes.
        </p>
        <p>
          * BUSINESS TRANSFERS.&nbsp;We may share or transfer your information
          in connection with, or during negotiations of, any merger, sale of
          company assets, financing, or acquisition of all or a portion of our
          business to another company.
        </p>
        <p>
          * AFFILIATES.&nbsp;We may share your information with our affiliates,
          in which case we will require those affiliates to honor this privacy
          policy. Affiliates include our parent company and any subsidiaries,
          joint venture partners or other companies that we control or that are
          under common control with us.
        </p>
        <p>
          * BUSINESS PARTNERS.&nbsp;We may share your information with our
          business partners to offer you certain products, services or
          promotions.
        </p>
        <h4>4. WHO WILL YOUR INFORMATION BE SHARED WITH?</h4>
        <p>
          <strong>
            IN SHORT: We only share information with the following third
            parties.
          </strong>
        </p>
        <p>
          We only share and disclose your information with the following third
          parties. We have categorized each party so that you may be easily
          understand the purpose of our data collection and processing
          practices. If we have processed your data based on your consent and
          you wish to revoke your consent, please contact us.
        </p>
        <p>
          * ADVERTISING, DIRECT MARKETING, AND LEAD GENERATION Google AdSense
          and Facebook Audience Network
        </p>
        <p>
          * CONTENT OPTIMIZATION Google Fonts,&nbsp;Google Site Search and
          YouTube video embed
        </p>
        <p>* DATA BACKUP AND SECURITY Google Drive Backup</p>
        <p>
          * SOCIAL MEDIA SHARING AND ADVERTISING Facebook
          advertising,&nbsp;Facebook social plugins and LinkedIn advertising
        </p>
        <p>
          * WEB AND MOBILE ANALYTICS Google Analytics,&nbsp;Google Ads and
          Google Tag Manager
        </p>
        <p>* WEBSITE HOSTING Namecheap</p>
        <p>* WEBSITE TESTING Optimizely</p>
        <h4>
          5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
          <strong>
            IN SHORT: We may use cookies and other tracking technologies to
            collect and store your information.
          </strong>
        </h4>
        <p>
          We may use cookies and similar tracking technologies (like web beacons
          and pixels) to access or store information. Specific information about
          how we use such technologies and how you can refuse certain cookies is
          set out in our Cookie Policy.
        </p>
        <h4>6. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
        <p>
          <strong>
            IN SHORT: We keep your information for as long as necessary to
            fulfill the purposes outlined in this privacy policy unless
            otherwise required by law.
          </strong>
        </p>
        <p>
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy policy, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting or other legal requirements). No purpose in this policy
          will require us keeping your personal information for longer than 2
          years.
        </p>
        <p>
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize it, or, if
          this is not possible (for example, because your personal information
          has been stored in backup archives), then we will securely store your
          personal information and isolate it from any further processing until
          deletion is possible.
        </p>
        <h4>7. HOW DO WE KEEP YOUR INFORMATION SAFE?</h4>
        <p>
          <strong>
            IN SHORT:&nbsp; We aim to protect your personal information through
            a system of organizational and technical security measures.
          </strong>
        </p>
        <p>
          We have implemented appropriate technical and organizational security
          measures designed to protect the security of any personal information
          we process. However, please also remember that we cannot guarantee
          that the internet itself is 100% secure. Although we will do our best
          to protect your personal information, transmission of personal
          information to and from our Sites is at your own risk. You should only
          access the services within a secure environment.
        </p>
        <h4>8. DO WE COLLECT INFORMATION FROM MINORS?</h4>
        <p>
          <strong>
            IN SHORT: We do not knowingly collect data from or market to
            children under 18 years of age.
          </strong>
        </p>
        <p>
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Sites, you represent that you are at least
          18 or that you are the parent or guardian of such a minor and consent
          to such minor dependent’s use of the Sites. If we learn that personal
          information from users less than 18 years of age has been collected,
          we will deactivate the account and take reasonable measures to
          promptly delete such data from our records. If you become aware of any
          data we have collected from children under age 18, please contact us
          at info@orangeastronaut.com.
        </p>
        <h4>9. WHAT ARE YOUR PRIVACY RIGHTS?</h4>
        <p>
          <strong>
            IN SHORT: You may review, change, or terminate your account at any
            time.
          </strong>
        </p>
        <p>
          If you are resident in the European Economic Area and you believe we
          are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority.
          You can find their contact details
          here:&nbsp;http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </p>
        <p>
          COOKIES AND SIMILAR TECHNOLOGIES:&nbsp;Most Web browsers are set to
          accept cookies by default. If you prefer, you can usually choose to
          set your browser to remove cookies and to reject cookies. If you
          choose to remove cookies or reject cookies, this could affect certain
          features or services of our Sites. To opt-out of interest-based
          advertising by advertisers on our Sites&nbsp;
          visit&nbsp;http://www.aboutads.info/choices/&nbsp;.
        </p>
        <h4>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h4>
        <p>
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (“DNT”) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. No uniform
          technology standard for recognizing and implementing DNT signals has
          been finalized. As such, we do not currently respond to DNT browser
          signals or any other mechanism that automatically communicates your
          choice not to be tracked online. If a standard for online tracking is
          adopted that we must follow in the future, we will inform you about
          that practice in a revised version of this Privacy Policy.
        </p>
        <h4>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h4>
        <p>
          <strong>
            IN SHORT: Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.&nbsp;
          </strong>
        </p>
        <p>
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below.
        </p>
        <p>
          If you are under 18 years of age, reside in California, and have a
          registered account with the Sites, you have the right to request
          removal of unwanted data that you publicly post on the Sites. To
          request removal of such data, please contact us using the contact
          information provided below, and include the email address associated
          with your account and a statement that you reside in California. We
          will make sure the data is not publicly displayed on the Sites, but
          please be aware that the data may not be completely or comprehensively
          removed from our systems.
        </p>
        <h4>12. DO WE MAKE UPDATES TO THIS POLICY?</h4>
        <p>
          <strong>
            IN SHORT:&nbsp; Yes, we will update this policy as necessary to stay
            compliant with relevant laws.
          </strong>
        </p>
        <p>
          We may update this privacy policy from time to time. The updated
          version will be indicated by an updated “Revised” date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy policy, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy policy
          frequently to be informed of how we are protecting your information.
        </p>
        <h4>13. HOW CAN YOU CONTACT US ABOUT THIS POLICY?</h4>
        <p>
          If you have questions or comments about this policy, you may email us
          at info@orangeastronaut.com or by post to:
        </p>
        <p>
          Orange Astronaut 116 Smithfield Street Pittsburgh, PA 15222 United
          States
        </p>
      </div>
    </ForegroundLayout>
  </FullWidthWrapper>
)

export default Privacy
